import { Injectable } from "@angular/core";
import { FluxoToleranciaService } from "./fluxo-tolerancia.service";
import { IdentificadorProcessoService } from "./identificador-processo.service";

// Utilizar este serviço para fazer inicializações

@Injectable({ providedIn: "root" })
export class AppInitService {
  constructor(
    private idFluxoSrv: IdentificadorProcessoService,
    private fluxoTolSrv: FluxoToleranciaService
  ) {

  }

  public init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.idFluxoSrv.init();
      this.fluxoTolSrv.init();
      resolve();
    });
  }
}
