import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class LoaderService {
  count: number = 0;

  mainLoader = false;

  constructor() {}

  show() {
    this.count++;
  }

  hide() {
    this.count--;

    if (this.count < 0) {
      this.count = 0;
    }
  }

  get showLoader(): boolean {
    return this.count != 0
  }

  showMainLoader() {
    this.mainLoader = true;
  }

  hideMainLoader() {
    this.mainLoader = false;
  }
}
