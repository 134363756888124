import { Injectable } from "@angular/core";
import { EnvService } from "../env.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { BasisActionsService } from "./basis-actions.service";
import { MessageService } from "../messages/message.service";
import { SecureLocalStorage } from "@services/storage/secure-local-storage";
import { constants_decorators } from "@decorators/constants.decorators";
import { Recomendacao } from "@models/recomendacao";
import { IRecomendacaoActionsFacade } from "@services/facade/recomendacao-actions.interface";
import { IEntidadeFacade } from "@services/facade/entidade.interface";
import { DataUtils } from "src/app/commons/data.utils";

@Injectable({
  providedIn: "root",
})
export class RecomendacaoActionsService
  extends BasisActionsService
  implements IRecomendacaoActionsFacade
{
  protected _CONSTANTS = constants_decorators;
  public entidadeSelecionada: Recomendacao;
  public entidadesFiltradas: Array<Recomendacao>;
  public entidadesRecuperadas: Array<Recomendacao>;

  public _dataUtils: DataUtils;
  protected urlBsae: string;

  constructor(
    protected _messageSrv: MessageService,
    protected _env: EnvService,
    protected secureLocalStorage: SecureLocalStorage,
    protected _http: HttpClient
  ) {
    super(_messageSrv, _env, secureLocalStorage, _http);
  }

  incluirDados(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  clonarDados(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  consultarDados(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  salvarDados(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  atualizarDados(dados: any): Promise<{}> {
    throw new Error("Method not implemented.");
  }
  criarDados(dados: any): Promise<{}> {
    throw new Error("Method not implemented.");
  }
  excluirDados(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  editarDados(entidadeSelecionada: IEntidadeFacade) {
    throw new Error("Method not implemented.");
  }
  editar(): Promise<void> {
    throw new Error("Method not implemented.");
  }
  cancelarDados() {
    throw new Error("Method not implemented.");
  }
  filtrarDados(filtro: string) {
    throw new Error("Method not implemented.");
  }

  getUrlEntidade(): string {
    return this._CONSTANTS.ROTAS.ENTIDADE.RECOMENDACAO;
  }

  recuperarDados(filter = null) {
    return this.recuperarTodas(filter);
  }

  async visualizarNovas() {
    return await this.marcarTodasComoVisualizadas();
  }

  private async marcarTodasComoVisualizadas() {
    this.entidadesRecuperadas = null;

    try {
      const urlBase =
        this.getUrlApiV2() +
        this.getUrlEntidade() +
        this._CONSTANTS.ROTAS.RECOMENDACAO.VISUALIZAR_TODAS;
      await this._http.get(urlBase, this.getJsonHeaders()).toPromise();
    } catch (err) {
      let msg = typeof err.error == "string" ? err.error : err.message;
      throw new Error(msg);
    }
  }

  async recuperarNovas() {
    this.entidadesRecuperadas = null;

    try {
      const urlBase =
        this.getUrlApiV2() +
        this.getUrlEntidade() +
        this._CONSTANTS.ROTAS.RECOMENDACAO.LISTAR_NOVOS;
      let recomendacoesRecuperadas = await this._http
        .get(urlBase, this.getJsonHeaders())
        .toPromise();

      this.entidadesRecuperadas = Object.assign([], recomendacoesRecuperadas);
    } catch (err) {
      let msg = typeof err.error == "string" ? err.error : err.message;
      throw new Error(msg);
    }

    return this.entidadesRecuperadas;
  }

  private async recuperarTodas(filters = null) {
    this.entidadesRecuperadas = null;
    let filter;

    try {
      let urlBase =
        this.getUrlApiV2() +
        this.getUrlEntidade() +
        this._CONSTANTS.ROTAS.RECOMENDACAO.LISTAR;

      if (filters) {
        filter = {};
        Object.keys(filters).forEach((key) => {
          if (!filters[key]) {
            return;
          }
          if (Array.isArray(filters[key]) && !filters[key].length) {
            return;
          }

          filter[key] = filters[key];
        });
      }

      // Filtra os dados
      if (filter) {
        let filterEncode = encodeURI(JSON.stringify(filter));
        urlBase = `${urlBase}?filter=${filterEncode}`;
      }

      let recomendacoesRecuperadas = await this._http
        .get(urlBase, this.getJsonHeaders())
        .toPromise();

      this.entidadesRecuperadas = recomendacoesRecuperadas as any;
    } catch (err) {
      let msg = typeof err.error == "string" ? err.error : err.message;
      throw new Error(msg);
    }

    return this.entidadesRecuperadas;
  }
}
