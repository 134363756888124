export class ErroItem {
    /**
     * Classe ErroItem contém os atributos para transmitir informações da
     * chamada de erro para a página de erro;
     * Nenhum parâmetro é obrigatório
     */
    titulo: string // ERRO, AVISO, etc
    subtitulo: string // Título "Código do erro, Código do aviso, etc."
    cod: string; // Código de erro
    mensagem: string; // Mensagem enviada de erro que será exibida na página de erro
    tipo: string; // Tipo de erro (Login, ERP, Rota, etc...) -> Verificar se faz sentido na remodelagem
    linkNome: string; // Alias do link que será disponibilizado na página de erro (ex. 'Home')
    linkRoute: string; // URL disponibilizada para página de erro que será ligada ao linkNome (ex: /home)
    linkParams: Object; // Objeto passado como parâmetro
    solucao: string; // Mensagem sugerindo uma resolução do problema
    detalhe: string; // Detalhes tecnicos do erro
    onClick: Function;
}
