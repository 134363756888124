import { Injectable } from '@angular/core';
import { constants_decorators } from '../../decorators/constants.decorators';

import { IMessageServiceFacade } from '../facade/message.interface';
import { ToastrService } from 'ngx-toastr';
import { MessageToastrService } from './message-ng-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends MessageToastrService  implements IMessageServiceFacade {

  public _toasterConfig;

  messageCacheMap: Map<string, {}>;

  public multiMessages: {};

  constructor(_toastr: ToastrService ) {

    super(_toastr);

    this.messageCacheMap = new Map([]);

  }

  multiMsgSet(msgID:string, message: {}){

    let multiMessages: MultiMessages;

    if(!this.multiMsgHas(msgID)){
        this.multiMsgInit(msgID, message['tit']);
    }

    multiMessages = <MultiMessages> this.messageCacheMap.get(msgID);
    multiMessages.mensagens.push(message);

  }

  multiMsgGet(msgID:string): MultiMessages{

    let multiMessages: MultiMessages;
    multiMessages = <MultiMessages> this.messageCacheMap.get(msgID);
    return multiMessages;

  }

  multiMsgHas(msgID:string): boolean{

    return  this.messageCacheMap.has(msgID);

  }

  multiMsgInit(msgID:string, msgTit: string){

    let multiMessages: MultiMessages = new MultiMessages();
    multiMessages.titulo = msgTit;
    multiMessages.mensagens = new Array();

    this.messageCacheMap.set(msgID, multiMessages);

  }

  multiMsgRemove(msgID:string){

    this.messageCacheMap.delete(msgID);

  }

  multiMsgSend(msgID:string){

    let multiMessages: MultiMessages;
    multiMessages = <MultiMessages> this.messageCacheMap.get(msgID);

    if(!this.multiMsgHas(msgID)){
      return;
    }

    let multiMessageSend = {
      config: this._config,
      msg: "",
      tit: "",
      level: multiMessages['level']
    }

    for(var i = 0; i < multiMessages.mensagens.length; i++){

      let message = multiMessages.mensagens[i];

      multiMessageSend.msg = multiMessageSend.msg != "" ?
                                        "<div>"+multiMessageSend.msg + "</div>" + message['msg'] : "<div>"+message['msg']+"<div>";
      multiMessageSend.level =  message['level'];
      multiMessageSend.tit =  multiMessages['titulo'];

    }

    this.showMessage(multiMessageSend);
    this.messageCacheMap.delete(msgID);

  }

  // showSucessMultiMessage(msgID:string, message:any){
  //   message['level'] = constants_decorators.LOG.LEVEL.SUCESS;
  //   this.multiMsgSet(msgID,message);
  // }

  // showInfoMultiMessage(msgID:string, message:any){
  //   message['level'] = constants_decorators.LOG.LEVEL.INFO;
  //   this.multiMsgSet(msgID,message);
  // }

  // showWarnMultiMessage(msgID:string, message:any){
  //   message['level'] = constants_decorators.LOG.LEVEL.WARN;
  //   this.multiMsgSet(msgID,message);
  // }

  showErrorMultiMessage(msgID:string, message:any){
    message['level'] = constants_decorators.LOG.LEVEL.ERROR;
    this.multiMsgSet(msgID,message);
  }


  // map.has() – to existence of an entry in the Map.
  // map.delete() – deletes an entry from the Map.
  // map.size – ‘size’ property will return size of Map.

  showMessage(message:{}){

    switch(message['level']){
      case constants_decorators.LOG.LEVEL.SUCESS:
        this.showSucessMessageConfig(message,message['config']);
        break;
      case constants_decorators.LOG.LEVEL.INFO:
        this.showInfoMessageConfig(message,message['config']);
        break;
      case constants_decorators.LOG.LEVEL.WARN:
        this.showWarnMessageConfig(message,message['config']);
        break;
      case constants_decorators.LOG.LEVEL.ERROR:
        this.showErrorMessageConfig(message,message['config']);
        break;
    }
  }

  showSucessMessage(message:any){

    super.showSucessMessageConfig(message, this._config);

  }

  showSucessMessageConfig(message:any, config:{}){
    super.showSucessMessageConfig(message, config);
  }

  showInfoMessage(message:any){

    super.showInfoMessageConfig(message, this._config);

  }


  showInfoMessageConfig(message:any, config:{}){
    super.showInfoMessageConfig(message, config);

  }

  showWarnMessage(message:any){

    super.showWarnMessageConfig(message, this._config);

  }

  showWarnMessageConfig(message:any, config:{}){
    super.showWarnMessageConfig(message, config);

  }

  showErrorMessage(message:any){

    super.showErrorMessageConfig(message, this._config);

  }

  showErrorMessageConfig(message:any, config:{}){

    super.showErrorMessageConfig(message, config);

  }


  logWarnMessage(message:{}){
    console.warn(message['msg']);
  }

  logErrorMessage(message:{}){
    console.error(message['msg']);
  }

  throwErrorException(message:{}){
    throw new Error(message['msg']);
  }

}

export class MultiMessages {
  titulo: string;
  mensagens: Array<{}>;
}
