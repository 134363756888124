<header fxLayout="row" *ngIf="isLogged && can" style="width: 100%; z-index: 1000;">
  <div fxLayout="row" style="width: 100%">
    <div fxLayout="column" fxLayoutAlign="center center" style="margin: 10px">
      <img style="padding: 7px" style="cursor: pointer; border: 0; outline: 0" src="{{ logoUrl }}" alt=""
        (click)="checkNavegacao($event,'home')" (load)="loadBadgeAmbiente()" />
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="ambiente.pre != ''">
      <mat-chip-list>
        <mat-chip selected [ngStyle]="{ 'background-color': ambiente.cor }" matTooltip="{{ ambiente.tooltip }}">
          <span style="text-align: center">{{ ambiente.pre }}</span>
        </mat-chip>
      </mat-chip-list>
    </div>
    <div *ngIf="statusErp && statusErp.status !== 'ok'" class="erp-icon-container" matTooltip="{{ getErpTooltip() }}"
      (click)="abrirErpStatusModal()">
      <mat-icon [class]="getErpIconClass()">settings_input_hdmi</mat-icon>
    </div>

    <!-- Relatórios Menu -->
    <mat-menu #relMenu="matMenu">
      <button mat-menu-item *ngFor="let item of relatoriosMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>

    <!-- Monitor Menu -->
    <mat-menu #monitorMenu="matMenu">
      <button mat-menu-item *ngFor="let item of homeMonitorMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>

    <!-- Administração Menu -->
    <mat-menu #adminMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="adminConfig" *ngIf="adminConfigMenu.canView">Configurações</button>
      <button mat-menu-item [matMenuTriggerFor]="adminGerenciamento" *ngIf="adminGerMenu.canView">Gerenciamento</button>
      <button mat-menu-item [matMenuTriggerFor]="adminProcesso" *ngIf="adminProcessoMenu.canView">Processo</button>
      <button mat-menu-item [matMenuTriggerFor]="sefazConfig" *ngIf="adminSefazMenu.canView">SEFAZ</button>
    </mat-menu>

    <!-- Administração Configurações -->
    <mat-menu #adminConfig="matMenu">
      <button mat-menu-item *ngFor="let item of adminConfigMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>
    <!-- Administração SEFAZ -->
    <mat-menu #sefazConfig="matMenu">
      <button mat-menu-item *ngFor="let item of adminSefazMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>
    <!-- Administração Gerenciamento -->
    <mat-menu #adminGerenciamento="matMenu">
      <button mat-menu-item *ngFor="let item of adminGerMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>

    <!-- Administração Processo -->
    <mat-menu #adminProcesso="matMenu">
      <button mat-menu-item *ngFor="let item of adminProcessoMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>

    <!-- Recebimento Menu -->
    <!-- <mat-menu #recebimentoMenu="matMenu">
      <button mat-menu-item *ngFor="let item of recebMenu.menu; trackBy:trackByMethod"
        (click)="checkNavegacao($event,item.rota,item.params)">
        {{item.titulo}}
      </button>
    </mat-menu> -->

    <!-- Help Menu -->
    <mat-menu #helpMenu="matMenu">
      <button mat-menu-item *ngFor="let item of sobreMenu.menu; trackBy:trackByMethod" (click)="helpGoTo(item.rota)">
        {{item.titulo}}
      </button>
    </mat-menu>
    <!-- Menus Principais -->
    <span class="container-header">
      <!-- Monitor Button -->
      <button class="button-header menuItem" id="relatorios" id="monitor" [matMenuTriggerFor]="monitorMenu"
        *ngIf="homeMonitorMenu.canView" mat-button>
        <mat-icon class="icon-inv" svgIcon="monitor" aria-hidden="false" style="margin-right: 10px"></mat-icon>
        Monitor
      </button>
      <!-- Relatórios Button -->
      <button class="button-header menuItem" id="relatorios" [matMenuTriggerFor]="relMenu" mat-button
        *ngIf="relatoriosMenu.canView">
        <mat-icon class="icon-inv" svgIcon="relatorio" aria-hidden="false" style="margin-right: 10px"></mat-icon>
        Relatórios
      </button>
      <!-- Administração Button -->
      <button class="button-header menuItem" id="administracao" #adminTrigger="matMenuTrigger"
        [matMenuTriggerFor]="adminMenu" mat-button *ngIf="visualizarAdministracao">
        <mat-icon class="icon-inv" svgIcon="administracao" aria-hidden="false" style="margin-right: 10px"></mat-icon>
        <span>Administração</span>
      </button>
      <!-- Recebimento Button -->
      <!-- <button class="button-header menuItem" id="recebimento" #recebimentoTrigger="matMenuTrigger"
        [matMenuTriggerFor]="recebimentoMenu" mat-button *ngIf="_clienteSrv.possuiNFe && can.visualizar_doca">
        <mat-icon class="icon-inv" svgIcon="doca" aria-hidden="false" style="margin-right: 10px"></mat-icon>
        <span>Recebimento</span>
      </button> -->
    </span>

    <div fxLayout="row" fxLayoutAlign="space-between" style="display: flex; margin: auto; margin-right: 17px">
      <div fxFlex="30">
        <button id="help" #helpTrigger="matMenuTrigger" [matMenuTriggerFor]="helpMenu" mat-button style="cursor: help">
          <mat-icon class="icon-inv" svgIcon="help" aria-hidden="false"></mat-icon>
        </button>
      </div>
      <div fxFlex="70">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon class="icon-inv" svgIcon="user" aria-hidden="false"></mat-icon>
          <span>{{ authSrv.usuario.nome }}</span>
          <mat-icon class="icon-inv" svgIcon="arrow-down" aria-hidden="false"></mat-icon>
        </button>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="menuTheme">Temas</button>
      <button mat-menu-item [routerLink]="['/logout']" routerLinkActive="router-link-active">
        Sair
      </button>
    </mat-menu>

    <mat-menu #menuTheme="matMenu">
      <button *ngFor="let tema of temas" mat-menu-item (click)="themeHandler(tema.nome)">
        {{ tema.descricao }}
      </button>
    </mat-menu>
  </div>
</header>
