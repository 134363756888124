import { ErroService } from '@services/erro.service';
import { Router } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";

export class Versao {
  api: string;
  erp: string;
  interpretador: string;
  interface: string;
}

@Injectable({
  providedIn: "root",
})
export class VersaoService {
  constructor(
    private _http: HttpClient,
    private _env: EnvService,
    private _router: Router,
    private _erroSrv: ErroService
  ) { }

  async fetchVersao(): Promise<Versao> {
    // Recupera as versões da API, ERP e Interpretador
    let promises = [];

    try {
      // Versão da API
      promises.push(
        this._http
          .get("api/versao")
          .toPromise()
      );

      // Versão do FrontEnd
      promises.push(
        this._http
          .get("assets/version.txt", { responseType: "text" })
          .toPromise()
      );

      // Aguarda o retorno
      let [versoes, versaoInterface] = await Promise.all(promises);
      return {
        api: versoes["api"],
        erp: versoes["erp"],
        interpretador: versoes["interpretador"],
        interface: versaoInterface,
      };
    } catch (err) {
      return null;
    }
  }

  /**
   * Busca a versão requerida do ERP pelo InvoiceCon e a versão atual do cliente
   */
  async buscarVersaoErpRequerida() {
    const url = `api/v2/erp/versaoErpInvoiceCon`;
    return this._http.get<Promise<{ versaoRequerida: string, versaoCliente: string }>>(url).toPromise();
  }
}
