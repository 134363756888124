import { Injectable } from "@angular/core";
import { WindowUtils } from "msal";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnvService {
  /**
   * Determina o cliente
   */
  static get cliente(): string {
    const pathParts = window.location.pathname.split('/');

    // O ambiente TST é o único que tem 2 partes: slot/cliente
    if (environment.environment === 'TST') {
      return pathParts[2] || 'cast';
    }

    // Se for um ambiente de desenvolvimento, devemos utilizar apenas o cliente cast
    // Se o link possuir não possuir numeros, entendemos que é um ambiente de testes do cliente
    // Então devemos retornar o nome do cliente (exceto para develop)
    const cliente = environment.hasOwnProperty('cliente') ? environment['cliente'] : ''
    const clienteTask = pathParts[1] || cliente || 'cast';
    if (['LOC', 'DEV', 'QAS'].includes(environment.environment) &&
      (parseFloat(clienteTask) || clienteTask == 'develop')) {
      return 'cast';
    }

    // Se for ambiente de homologação, suporte ou produtivo, pegamos pelo primeiro parametro após a URL
    return clienteTask;
  }

  static get ambienteHeader(): string {
    const pathParts = window.location.pathname.split('/');
    const cliente_task = pathParts[1] || 'cast';

    if (environment.hasOwnProperty('ambiente')) {
      return environment['ambiente'];
    }

    // Se for o ambiente local, enviamos como localhost, pois não deve interferir no funcionamento
    if (environment.environment == 'LOC') {
      return 'localhost';
    }

    // Se estiver usando uma configuração de algum ambiente, devemos apontar para a API develop
    if (window.location.origin.includes('localhost')) {
      return 'develop';
    }

    // Se for um ambiente de desenvolvimento ou suporte, devemos utilizar o slot/cliente
    if (['DEV', 'TST', 'SUP'].includes(environment.environment)) {
      return `${environment.environment.toLowerCase()}_${cliente_task}`;
    }

    // Ambientes de homologação e teste não tem slot
    if (['HOM', 'QAS'].includes(environment.environment)) {
      return environment.environment.toLowerCase();
    }

    // Ambiente produtivo
    return `prd_${cliente_task}`
  }

  static assetsUrl(url) {
    return `${EnvService.getRootPath()}/${url}`
  }

  /**
   * Monta o caminho raiz para todos os links utilizados
   */
  private static getRootPath() {
    let root = "";
    const pathParts = window.location.pathname.split("/");
    if (pathParts[1]) {
      root += `/${pathParts[1]}`;
      if (window.location.host.startsWith("tst") && pathParts[2]) {
        root += `/${pathParts[2]}`;
      }
    }
    return root;
  }

  /**
   * Coleta o base url do server
   * @returns uri
   */
  static getBaseUrl() {
    return `${window.location.origin}${EnvService.getRootPath()}/#/`;
  }

  /**
   * Método para redirecionar
   * @param redirectTo path para redirecionar
   * @param newTab boolean abre em nova aba?
   */
  static redirectHref(redirectTo: string, newTab = false) {
    // Caso o url comece com barra (/)
    if (redirectTo.charAt(0) === "/") {
      // Remove a barra do URL
      redirectTo = redirectTo.substring(1);
    }
    const uri = EnvService.getBaseUrl();
    if (newTab) {
      window.open(`${uri}${redirectTo}`, "_blank");
    } else {
      window.location.href = `${uri}${redirectTo}`;
    }
  }
}
