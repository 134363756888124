import { ErpService } from '@services/erp.service';
import { EnvService } from "./../../services/env.service";
import { Component, OnInit, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy, AfterViewInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthService } from "@services/auth.service";
import { Router } from "@angular/router";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { ClienteService } from "@services/cliente.service";
import { ModalSobreComponent } from "@modals/modal-sobre/modal-sobre.component";
import { CommErp } from '@services/erp.service';
import { ModalErpOffComponent } from '@modals/modal-erp-off/modal-erp-off.component';
import { ConfigService } from '../../services/config.service';
import { environment } from 'src/environments/environment';
import { permissoes_decorators } from '@decorators/permisssoes.decorators';
import { Subscription } from 'rxjs';
// tslint:disable:variable-name

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  subscribes: Array<Subscription> = [];
  isLogged: boolean;
  isAuthArchbee = false;
  username: string;
  logoUrl: string = EnvService.assetsUrl("assets/img/invoice-dark-theme.png");
  temas = [
    { nome: "dark", descricao: "Tema escuro" },
    { nome: "light", descricao: "Tema claro" },
  ];
  can: any;
  // Por enquanto vindo como default o tema dark. Mas tem que fazer a busca no localStorage
  temaAtual = "dark";

  @Output() themeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() toggleDrawer: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("adminTrigger") adminMenu: MatMenuTrigger;

  ambiente = {
    pre: "",
    tooltip: "",
    cor: "",
  };

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public authSrv: AuthService,
    private _router: Router,
    public _clienteSrv: ClienteService,
    public dialog: MatDialog,
    private _erpSrv: ErpService,
    private _configSrv: ConfigService,
  ) {
    iconRegistry.addSvgIcon(
      "administracao",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/administracao.svg"))
    );
    iconRegistry.addSvgIcon(
      "user",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/person.svg"))
    );
    iconRegistry.addSvgIcon(
      "arrow-down",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/arrow-down.svg"))
    );
    iconRegistry.addSvgIcon(
      "relatorio",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/relatorio.svg"))
    );
    iconRegistry.addSvgIcon(
      "monitor",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/monitor.svg"))
    );
    iconRegistry.addSvgIcon(
      "portaria",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/portaria.svg"))
    );
    iconRegistry.addSvgIcon(
      "doca",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/doca.svg"))
    );
    iconRegistry.addSvgIcon(
      "curadoria",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/curadoria4.svg"))
    );
    iconRegistry.addSvgIcon(
      'notifications',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/notifications.svg'))
    );
    iconRegistry.addSvgIcon(
      "help",
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl("assets/img/help.svg"))
    );


    const temaAplicacao = localStorage.getItem("temaAplicacao");
    if (temaAplicacao && temaAplicacao.length > 1) {
      this.temaAtual = temaAplicacao;
      this.logoUrl = EnvService.assetsUrl(`assets/img/invoice-${temaAplicacao}-theme.png`);
    }

    this.username = this.authSrv.usuario.nome;
    if (this.username !== "") { this.isLogged = true; }
    else { this.isLogged = false; }
  }

  ngOnInit() {
    // "Ouve" as atualizações das permissões
    // Para que o header possa respeitar as atualizações
    const subscribe = this.authSrv.permissionsNotify.subscribe(() => {
      this.setPermissions();
    });

    this.subscribes.push(subscribe);
  }

  ngOnDestroy() {
    this.subscribes.forEach(subscribe => {
      subscribe.unsubscribe();
    });
  }

  ngAfterViewInit() {
    const url = window.location.href;
    if (url.includes("/admin/")) {
      this.change("administracao");
    } else if (url.includes("relatorio")) {
      this.change("relatorios");
    } else if (url.includes("recebimento")) {
      this.change("recebimento");
    } else {
      this.change("monitor");
    }
  }

  ngAfterViewChecked(): void {
    const url = window.location.href;
    if (url.includes("/admin/")) {
      this.change("administracao");
    } else if (url.includes("relatorio")) {
      this.change("relatorios");
    } else if (url.includes("recebimento")) {
      this.change("recebimento");
    } else if (url.includes("processo/curadoria")) {
      this.change("curadoria");
    } else {
      this.change("monitor");
    }
  }

  get statusErp(): CommErp {
    const erp: CommErp = this._erpSrv.statusErp;
    if (!this._erpSrv.statusErp && erp) {
      this._erpSrv.statusErp = erp;
    }
    return erp;
  }

  set statusErp(erp: CommErp) {
    this._erpSrv.statusErp = erp;
  }

  change(name: string) {
    const buttons = document.querySelectorAll(".button-header");
    const buttonClicked = document.getElementById(name);

    if (buttons.length > 0) {
      buttons.forEach((button) => {
        if (
          button &&
          button.classList &&
          button.classList.contains("selected")
        ) {
          button.classList.remove("selected");
        }
      });
      if (buttonClicked?.classList) {
        buttonClicked.classList.add("selected");
      }
    }
  }

  logout() { }

  /**
   * Realiza a navegação para a rota desejada
   * @param event evento do mouse
   * @param rota rota designada
   * @param params parametros da rota
   */
  async checkNavegacao(event: MouseEvent, rota: string, params = null) {
    // Caso seja ctrl click
    if (event.ctrlKey) {
      let parameters = '';
      // Caso tenha parametros, transforma em urlSearch
      if (params) {
        const searchParams = new URLSearchParams(params);
        // Define o parametro
        parameters = `?${searchParams.toString()}`;
      }
      // URL para redirecionar
      const url = `${rota}${parameters}`;
      // Redirect para nova guia
      EnvService.redirectHref(url, true);
    } else {
      // Navega pela rota
      this._router.navigate([rota], {
        queryParams: params
      });
    }

  }

  async navigateToArchbee(rota: string) {
    // const result = await this._configSrv.getTokenArchBee();
    const url = `https://app.archbee.com/public/${rota}`;
    window.open(url, '_blank');
  }

  redirectTo(uri: string) {
    this._router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this._router.navigate([uri]));
  }

  loadBadgeAmbiente() {
    this.ambiente.pre = environment.environment;
    this.ambiente.tooltip = environment.tooltip_header;
    this.ambiente.cor = environment.environment_color_header;
  }

  themeHandler(tema: any) {
    if (this.temaAtual !== tema) {
      this.logoUrl = EnvService.assetsUrl(`assets/img/invoice-${tema}-theme.png`);
      this.temaAtual = tema;
      this.themeChange.emit(tema);
    }
  }

  get visualizarAdministracao() {
    return this.adminConfigMenu.canView || this.adminGerMenu.canView || this.adminProcessoMenu.canView;
  }

  sobre() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.position = {
      top: "2vh",
    };
    dialogConfig.backdropClass = "custom-backdrop";
    this.dialog.open(ModalSobreComponent, dialogConfig);
  }

  getErpIconClass() {
    if (!this.statusErp) {
      return 'erp-icon-ok';
    }
    return `erp-icon-${this.statusErp.status}`;
  }

  getErpTooltip() {
    switch (this.statusErp.status) {
      case 'ok':
        return '';
      case 'conectando':
        return 'Realizando comunicação com ERP';
      case 'erro':
        return `Sem comunicação com ERP
          (${this.statusErp.msg || 'Erro inesperado de conexão com ERP'})`;
      default:
        return '';
    }
  }

  abrirErpStatusModal() {
    if (!this.statusErp || this.statusErp.status !== 'erro') {
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.position = {
      top: "2vh",
    };
    dialogConfig.width = "70%";
    dialogConfig.height = "75%";
    dialogConfig.panelClass = "custom-modalBox";
    dialogConfig.backdropClass = "custom-backdrop";
    this.dialog.open(ModalErpOffComponent, dialogConfig);
  }

  /**
   * Seta o valor da nossa variável this.can
   * Atribui permissões ao usuário
   */
  async setPermissions(): Promise<void> {
    // Todas as permissões que desejo pra esse tela
    const permissoesMenu = [
      'visualizar_rel_aprov',
      'usuarios',
      'perfis',
      'conversao_medidas',
      'configuracoes',
      'requisitante_reatribuicao',
      'admin_imposto',
      'visualizar_doca',
      `${permissoes_decorators.CURADORIA.MODELO.ACESSAR}`,
      `${permissoes_decorators.CURADORIA.PENDENCIA.ACESSAR}`,
      `${permissoes_decorators.CURADORIA.PENDENCIA.VISUALIZAR}`,
      'visualizar_home',
      'certificados',
      'configuracoes_cnpj',
      'manifesto_destinatario_monitor',
      'nfe_cnpj',
      'cte_cnpj',
      'auxiliar',
      'fornecedores_confiaveis',
      'notas_canceladas',
      'tomador_destinatario',
      'visualizar_rel_anexo',
      'visualizar_fluxo_processo',
      'visualizar_fluxo_tolerancia',
      'recebimento_documentos',
      'visualizar_rel_avancado',
    ];
    // Definição dos valores das permissões
    this.can = this.authSrv.canPermissions(permissoesMenu);

    if (!this._configSrv.getConfigs().length) {
      await this._configSrv.fetchConfigs().toPromise();
    }

    const manifestoEnabled = this._configSrv.getConfigBySlug("manifesto_destinatario")?.valor ===
      "sim";

    if (!manifestoEnabled) {
      this.can.manifesto_destinatario_monitor = false;
    }
  }

  /**
   * Rastrear coleção para re-renderização
   * @param index index
   * @param el elemento
   * @returns rastreio
   */
  trackByMethod(index: number, el: any) {
    return el.rota;
  }

  menuResponse(menu: any) {
    return {
      menu: menu.filter((m) => m.regra),
      canView: menu.map((m) => m.regra).find((v) => !!v)
    };
  }

  /**
   * Menu de relatórios
   */
  get relatoriosMenu() {
    const menu = [{
      titulo: 'Serviços Pendentes de Aprovação',
      rota: '/relatorios/aprovacao',
      regra: this.can.visualizar_rel_aprov
    },
    {
      titulo: 'Processamento de Arquivos',
      rota: '/relatorios/anexos',
      regra: false, // this.can.visualizar_rel_anexo
    },
    {
      titulo: 'Volumetria de Processos',
      rota: '/relatorios/volumetria-processos',
      regra: this.can.visualizar_rel_anexo
    },
    {
      titulo: 'Relatório Auxiliar',
      rota: '/relatorios/auxiliar',
      regra: this.can.auxiliar
    },
    {
      titulo: 'Relatório Notas Canceladas',
      rota: '/relatorios/notas-canceladas',
      regra: this.can.notas_canceladas
    },
    {
      titulo: 'Relatórios Avançados',
      rota: '/relatorios/avancados',
      regra: this.can.visualizar_rel_avancado,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Admin/Config Menu
   */
  get adminConfigMenu() {
    const menu = [{
      titulo: 'Configurações (Parâmetros)',
      rota: 'admin/configuracoes',
      regra: this.can.configuracoes
    },
    {
      titulo: 'Configurações de Validações',
      rota: 'admin/validacoes',
      regra: this.can.configuracoes
    },
    {
      titulo: 'Configurações de Impostos',
      rota: 'admin/imposto',
      regra: this.can.admin_imposto
    },
    {
      titulo: 'Configurações de Logotipo',
      rota: 'admin/config-cnpj',
      regra: this.can.configuracoes_cnpj,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Sefaz Menu
   */
  get adminSefazMenu() {
    const menu = [{
      titulo: 'Cadastro de Certificados',
      rota: 'admin/certificados',
      regra: this.can.certificados && this._clienteSrv.possuiNFe
    },
    {
      titulo: 'Gerenciamento CNPJ NF-e',
      rota: 'admin/nfe-cnpj',
      regra: this.can.nfe_cnpj && this._clienteSrv.possuiNFe
    },
    {
      titulo: 'Gerenciamento CNPJ CT-e',
      rota: 'admin/cte-cnpj',
      regra: this.can.cte_cnpj && this._clienteSrv.possuiCTe,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Admin/Gerenciamento Menu
   */
  get adminGerMenu() {
    const menu = [
    {
      titulo: 'Gerenciamento de Grupos de Filiais',
      rota: 'admin/grupos',
      regra: this.can.usuarios
    },
    {
      titulo: 'Gerenciamento de Usuários',
      rota: 'admin/usuarios',
      regra: this.can.usuarios
    },
    {
      titulo: 'Gerenciamento de Perfis',
      rota: 'admin/perfis',
      regra: this.can.perfis
    },
    {
      titulo: 'Gerenciamento Tomador/Destinatário',
      rota: 'admin/tomador',
      regra: this.can.tomador_destinatario,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Admin/Processo Menu
   */
  get adminProcessoMenu() {
    const menu = [{
      titulo: 'Unidades de Medida',
      rota: 'admin/unidade-medida',
      regra: this.can.conversao_medidas && this._clienteSrv.possuiNFe
    },
    {
      titulo: 'Reclassificação de Requisitantes',
      rota: 'admin/reclassificar-requisitante',
      regra: this.can.requisitante_reatribuicao
    },
    {
      titulo: 'Fornecedores Confiáveis',
      rota: 'admin/fornecedores-confiaveis',
      regra: this.can.fornecedores_confiaveis
    },
    {
      titulo: 'Identificador Fluxo Processo',
      rota: 'admin/idprocesso',
      regra: this.can.visualizar_fluxo_processo && this._clienteSrv.possuiNFe,
    },
    {
      titulo: 'Tolerância por Fluxo Processo',
      rota: 'admin/fluxo-tolerancia',
      regra: this.can.visualizar_fluxo_tolerancia,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Monitor Menu
   */
  get homeMonitorMenu() {
    const menu = [{
      titulo: 'Fiscal',
      rota: 'home',
      regra: this.can.visualizar_home
    },
    {
      titulo: 'Recebimento',
      rota: '/recebimento',
      params: null,
      regra: this.can.visualizar_doca,
    },
    {
      titulo: 'Manifesto',
      rota: 'manifesto',
      regra: this.can.manifesto_destinatario_monitor,
    }];

    return this.menuResponse(menu);
  }

  /**
   * Recebimento Menu
   */
  get recebMenu() {
    const menu = [{
      titulo: 'Novo',
      rota: '/recebimento/sintese-recebimento',
      params: { tipo: 'simples' },
      regra: this.can.recebimento_documentos
    },
    ];

    return this.menuResponse(menu);
  }

  /**
   * Sobre Menu
   */
  get sobreMenu() {
    const menu = [{
      titulo: 'Documentação',
      rota: 'm4F759FRt3cXr6im3yF61',
    },
    {
      titulo: 'Sobre',
      rota: '',
    }];

    return {
      menu
    };
  }

  /**
   * Ajuda vai para?
   * @param rota rota
   * @returns void
   */
  async helpGoTo(rota?: string) {
    if (rota) {
      await this.navigateToArchbee(rota);
      return;
    }
    this.sobre();
  }

}
